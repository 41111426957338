.teaser {
	position: relative;
	width: 100%; 
	overflow: hidden;

	.bg_img {
		position: relative;
		width: 100%;
	}

	.logotop {
		text-align: center;
		img{max-width: 300px;}
	}

	.lijn {
		position: absolute; 
		
		&.lijn_01 {
			max-width: 100%;
			min-width: 600px;
			width: 100%; 
			bottom: 22%; 
			right: 0px; 
		}
		&.lijn_02 {
			max-height: 100%;
			bottom: -20%; 
			right: 0; 
		}
		&.lijn_04 {
			max-width: 100%;
			max-height: 100%;
			top: 0; 
			right: 310px; 
		}

		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	
}