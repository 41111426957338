@charset 'UTF-8';

/* =============================================================================
   HTML5 display definitions
   ========================================================================== */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary { display: block; }

audio,
canvas,
video { display: inline-block; }

audio{ 
   :not([controls]) { display: none; height: 0; }
}
[hidden] { display: none; }

* { -webkit-tap-highlight-color: rgba(255, 255, 255, 0); -webkit-touch-callout: none; -webkit-appearance: none; -webkit-border-radius: 0; outline: none; }


/* =============================================================================
   Base
   ========================================================================== */

/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 * 3. facebook bug not showing canvas in ie7/ie8 positon:relative weghalen
 */

html { font-size: 1em; line-height: 1.4; overflow-y: scroll; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; min-height:100%; height:100%; width:100%; -webkit-font-smoothing: antialiased;}

html,
button,
input,
select,
textarea { font-family: sans-serif; color: #222; }

/*
 * Prevent margin bug from nested div http://stackoverflow.com/questions/4015053/top-margin-inside-a-div-do-not-work
 */
body { margin: 0; font-size: 100%; font-size: 1em; line-height: 1.4; height:100%; width:100%; background:#fff; float:left;}

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */
audio,
canvas,
img,
video {vertical-align: middle;}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */

::-moz-selection { background: #7ed321; color: #fff; text-shadow: none; }
::selection { background: #7ed321; color: #fff; text-shadow: none; }

/*
 * The default box-model is set to “content-box” which means it does not include padding and borders into the width value.
 * The default box-sizing would make the width be 100% plus the padding but using border-box would include the padding in the width.
 */
*,
*:before,
*:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

/* =============================================================================
   Links
   ========================================================================== */
a{cursor:pointer;}
a:focus { outline: 0; }

/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
a:hover,
a:active { outline: 0; }


/* Added to get rid of the grey background on link press in IE10 */
a:hover,
a:active { outline: 0; }
a:link{ background-color:transparent}
a:visited{ background-color:transparent}
a:hover{ background-color:transparent}
a:active{ background-color:transparent}

a[href^=tel]{ color:inherit; }

.clickable{cursor:pointer;}

/* =============================================================================
   Typography
   ========================================================================== */

abbr[title] { border-bottom: 1px dotted; }

b,
strong { font-weight: bold; }

blockquote { margin: 1em 40px; }

dfn { font-style: italic; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

ins { background: #ff9; color: #000; text-decoration: none; }

mark { background: #ff0; color: #000; font-style: italic; font-weight: bold; }

/* Redeclare monospace font family: h5bp.com/j */
pre,
code,
kbd,
samp { font-family: monospace, serif; _font-family: 'courier new', monospace; font-size: 1em; }

/* Improve readability of pre-formatted text in all browsers */
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }

q { quotes: none; }
q:before,
q:after { content: ""; content: none; }

small { font-size: 85%; }

/* Position subscript and superscript content without affecting line-height: h5bp.com/k */
sub,
sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }

p,
h1,
h2,
h3,
h4 { margin:0; padding:0; }

/* =============================================================================
   Lists
   ========================================================================== */

ul,
ol { margin: 0; padding: 0; }
dd { margin: 0 0 0 40px; }
nav ul,
nav ol { list-style: none; list-style-image: none; margin: 0; padding: 0; }


/* =============================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/i/440
 */

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }

/*
 * Correct overflow not hidden in IE9
 */

svg:not(:root) { overflow: hidden; }


/* =============================================================================
   Figures
   ========================================================================== */

figure { margin: 0; }


/* =============================================================================
   Forms
   ========================================================================== */

form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }
textarea{resize:none;}

/*disable arrows on field type number*/
form input[type='number']::-webkit-outer-spin-button,
form input[type='number']::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance:textfield; }

/* Indicate that 'label' will shift focus to the associated form element */
label { cursor: pointer; }

/*
 * 1. Correct color not inheriting in IE6/7/8/9
 * 2. Correct alignment displayed oddly in IE6/7
 */

legend { border: 0; *margin-left: -7px; padding: 0; white-space: normal; }

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */

button,
input,
select,
textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 */

button,
input { line-height: normal; }

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 * 3. Correct inner spacing displayed oddly in IE7 (doesn't effect IE6)


button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; *overflow: visible; }
*/
/*
 * Re-set default cursor for disabled elements
 */

button[disabled],
input[disabled] { cursor: default; }


/*
 * Consistent box sizing and appearance
 */

input[type="checkbox"],
input[type="radio"] { box-sizing: border-box; padding: 0; *width: 13px; *height: 13px; }
input[type="checkbox"]{appearance:checkbox; -moz-appearance:checkbox; -webkit-appearance:checkbox;}
input[type="radio"]{appearance:radio; -moz-appearance:radio; -webkit-appearance:radio;}
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button { -webkit-appearance: none; }

/*
 * Remove inner padding and border in FF3/4: h5bp.com/l
 */

button::-moz-focus-inner,
input::-moz-focus-inner { border: 0; padding: 0; }
button:focus, input:focus,
textarea:focus { outline: none; }

/*
 * 1. Remove default vertical scrollbar in IE6/7/8/9
 * 2. Allow only vertical resizing
 */

textarea { overflow: auto; vertical-align: top; resize: vertical; }

/* Colors for form validity */
input:valid,
textarea:valid {  }

input:invalid,
textarea:invalid { background-color: #f0dddd; }

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {-webkit-appearance: none; border-radius: 0;}

/*
  * Placeholder color
  */
::-webkit-input-placeholder { /* WebKit browsers */ color: #a7a7a7; }
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */ color: #a7a7a7; }
::-moz-placeholder { /* Mozilla Firefox 19+ */ color: #a7a7a7; }
:-ms-input-placeholder { /* Internet Explorer 10+ */ color: #a7a7a7; }


/* =============================================================================
   Tables
   ========================================================================== */

table { border-collapse: collapse; border-spacing: 0; }
td { vertical-align: top; }


/* =============================================================================
   Shorthand classes
   ========================================================================== */

.float_left { float: left; }
.float_right { float: right; }

.hide { display: none; }
.show { display: block; }

/* ==========================================================================
   Focus point
   ========================================================================== */

.focuspoint { position: relative; overflow: hidden; }
.focuspoint img { position: absolute; left: 0; top: 0; margin: 0; display: block; width: auto; height: auto; min-width: 100%; min-height: 100%; max-height: none; max-width: none; }
