@charset 'UTF-8';

@import '_inc-boilerplate';
@import '_inc-mixins';

/* ==|== plugin styles ======================================================
	Put all css used by plugins inside include
============================================================================= */
@import '_inc-plugins';

/* ==|== primary styles =====================================================
	Author: Keistoer Interactieve Media ([name])
============================================================================= */


/* --|-- Sass Variables -----------
	Put all sass variables here (usage: color:$variable; )
---------------------------------- */

/* fonts */
$_arial: Arial, Helvetica Neue, Helvetica, sans-serif;
$_opensans: Open Sans, sans-serif;

/* colors */
$_black: #000;
$_white: #fff;
$_offWhite: #fafafa;
$_lightGreen: #7ed321;
$_btnHover: #70b229;
$_greyish: #a7a7a7;


/* --|-- Styles --------------
	Site styles
---------------------------------- */

@import '_inc-teaser';

html {
	min-width: 100%;
	font-weight: 300 !important; 
	overflow-x: hidden !important;
	width: 100%;
}
a {
	color: $_lightGreen;
	text-decoration: underline;
}

.content_wrapper {
	width: 100%;
	max-width: 740px;
	padding: 0 45px;
	margin: 0 auto;
	position: relative;

	&.two_column {
		display: flex;
		justify-content: space-between;
		
		@media only screen and (max-width: 767px) {
			display: block;
		}
		
		.column {
			text-align: left;
			max-width: 100%;
			
			&.img {
				img {width: 100%;}
			}

			
		}

		&.first{
			max-width: 840px;
			margin-bottom: 60px;

			.text {
				width: 40%;
			}
			.img {
				width: 55%;
				position: relative;
				margin-top: -90px;
			}

			@media only screen and (min-width: 1023px) {
				right: -60px;
			}

			@media only screen and (max-width: 767px) {
				max-width: 740px;
				right: inherit;
				.img {display: none;}
				.text {width: 100%;}
			}
		}

		&.second{
			max-width: 940px;
			left: -70px;
			.text {
				width: 35%;
			}
			.img {
				width: 60%;
				position: relative;
				margin-top: -30px;
				overflow: hidden;
				
				.lijn {
					position: absolute; top: 0px; left: -25%; max-width: 100%; max-height: 100%;
				}
			}

			@media only screen and (max-width: 767px) {
				max-width: 740px;
				left: inherit;
				.img {display: none;}
				.text {width: 100%;}
			}
		}


		

	}
}

#stijltang-big {
	z-index: 0;
	position: relative;
}

.logoheader {
	position: absolute;
	padding-top: 0px;
	margin-top: -325px;
	width: 100%;
	height: 40px;
}

.logotop {
	position: absolute;
	width: 100%;
	top: 60px;
	@media only screen and (max-width: 550px) {
		top: 50px;
	}
	img {
		display: block;
		position: relative;
		margin: 0 auto;
		@media only screen and (max-width: 550px) {
			width: 80%;
		}
	}
}

.slogan {
	font-family: $_opensans;
	font-size: 24px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: $_lightGreen;
}

#klantwordtgeholpen {
	z-index: 9999;
	width: auto;
	position: absolute;
	margin-top: -270px;
	margin-left: -60px;
	@media only screen and (max-width: 1024px) {

	}
	@media only screen and (max-width: 768px) {
		position: relative;
		margin: 0 auto;
		margin-top: -100px;
		margin-left: 50px;
		max-width: 100%;
		width: 30%;
	}
	@media only screen and (max-width: 450px) {
		position: relative;
		margin: 0 auto;
		max-width: 100%;
	}
}

.textblockcolumn {
	position: relative;
	width: 100%;
	text-align: center;
	margin: 0 auto;
	font-size: 18px;
	@media only screen and (max-width: 550px) {
		font-size: 16px;
	}
}

.defaultbutton {
	border: none;
	margin: 0 auto;
	margin-top: 30px;
	padding: 15px 40px;
	border-radius: 50px;
	background-color: $_lightGreen;
	color: $_offWhite;
	font-size: 24px;
	font-style: italic;
	@media only screen and (max-width: 550px) {
		font-size: 18px;
		padding: 15px 30px;
	}
	&:hover {
		background-color: $_btnHover;
		cursor: pointer;
	}
}

.social {
    text-align: right; 
    padding-bottom: 10px;
    span {
        vertical-align: sub;
    }
    a {
        width: 28px;
        height: 28px;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        margin: 0px 0px 0px 0px; 
    }
    a::before {
        color: black;
        font-family: "fontAwesome";
        text-decoration: none;
        font-size: 16px;
        vertical-align: -18%; }
    a.inst::before {
        content: "\f16d"; }
    a.fb::before {
        content: "\f09a"; }
    a.pin::before {
        content: "\f231"; }
    a.tw::before {
        content: "\f099"; }
    a.in::before {
        content: "\f0e1"; }
    a.g::before {
        content: "\f0d5"; }
}


.instagram {
    text-align: center;
    span {
        vertical-align: sub;
    }
    a {
        width: 28px;
        height: 28px;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        margin: 0px 0px 0px 0px; 
    }
    a::before {
        color: black;
        font-family: "fontAwesome";
        text-decoration: none;
        font-size: 16px;
        vertical-align: -18%; }
    a.inst::before {
        content: "\f16d"; }
    a.fb::before {
        content: "\f09a"; }
    a.pin::before {
        content: "\f231"; }
    a.tw::before {
        content: "\f099"; }
    a.in::before {
        content: "\f0e1"; }
    a.g::before {
        content: "\f0d5"; }
}


#team {
	margin-top: 60px;
	z-index: 0;
	position: relative;
	max-width: 100%;
}

.btn-sub {
	color: $_greyish;
	padding-top: 6px;
}




.divider {
	height: 100px;
}



#knippen {
	max-width: 100%;
	margin-top: 30px;
	margin-bottom: 60px;
}

footer {
	margin-top: 40px;
	width: 1920px;
	height: 780px;
	display: inline-block;
	text-align: center;
	color: $_offWhite;
	overflow: hidden;
	
	.footer_bg {
		background: black;
		margin-top: -50px;
		position: relative;
		overflow: hidden;

		.lijn {
			position: absolute;
			transform: rotate(-15deg);
			max-height: 100%;
			right: 15%;
			@media only screen and (max-width: 1200px) {
				right: 10%;
			}
			@media only screen and (max-width: 1024px) {
				display: none;
			}
		}
	}
	
	#map {
		width:100%;
		height:300px;
		background:grey;
		clip-path: polygon(0 10%, 100% 0, 100% 95%, 0 85%);
		position: relative;
		z-index: 99;
	}
}

.title-l {
	font-size: 24px;
	font-weight: 300;
	@media only screen and (max-width: 550px) {
		font-size: 20px;
	}
}

.instagramfeed {
	margin-top: 60px;
	margin-bottom: 60px;
	width: 100%;
	position: relative;
}

.max-width {
	max-width: 100vw;
	max-width: 100%;
}

.logobot {
	padding-top: 100px;
}

.logobot {
	text-align: center;
	img{max-width: 300px;}
}


.footertext {
	margin-top: -20px;
	padding-top: 60px;
	padding-bottom: 100px;
}

#gebouw {
	position: absolute;
	margin-left: -600px;
	margin-top: 60px;

	@media only screen and (max-width: 1024px) {
		margin-left: 0px;
		position: relative;
		width: 40%;
	}
	@media only screen and (max-width: 450px) {
		margin-left: 0px;
		position: relative;
		width: 80%;
	}
}






/*dynamic height sticky footer*/
#site_wrapper { display: table; height: 100%; width: 100%; font-family: $_opensans; padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);}
header,
#main,
footer { display: table-row; height: 1px; }
#main { height: auto;}
/*end dynamic height sticky footer*/


/* ==|== media queries ======================================================
	Media queries for Responsive Design.
	========================================================================== */
@import '_inc-mediaqueries';

.desktop {
	display: block;
		@media only screen and (max-width: 550px) {
			display: none;
		}
}

.onlydesktop {
	display: block;
		@media only screen and (max-width: 1425px) {
			display: none;
		}
}

.mobile {
	display: none;
		@media only screen and (max-width: 550px) {
			display: block;
		}
}


/* ==|== non-semantic helper classes ========================================
	Please define your styles before this section.
============================================================================= */

/* Contain floats: h5bp.com/q */

.clearfix {
	&::before,
	&::after { content: ''; display: table; clear: both; }
}


/* ==|== Print css ======================================================
	CSS for printing.
========================================================================= */
@import '_inc-print';
